import { useState, useEffect } from 'react';
import axios from 'axios';
import Map from './Map';
import InfoCarrousel from './InfoCarrousel';
import InfoBasic from './InfoBasic';
import Loading from '../Loading';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const MapDashboard = () => {
  const { width: windowWidth } = useWindowDimensions();
  const [currentState, setCurrentState] = useState();
  const [currentStateData, setCurrentStateData] = useState([]);
  const [filter, setFilter] = useState({
    primera: true,
    segunda: true,
    tercera: true
  });
  const [statesData, setStatesData] = useState([]);
  const [sedes, setSedes] = useState(null);
  const [popup, setPopup] = useState(false);

  const filterStages = e => {
    const stage = e.target.id;
    setFilter(prev => {
      if (stage === 'primera') return { ...prev, primera: true, segunda: false, tercera: false };
      if (stage === 'segunda') return { ...prev, primera: false, segunda: true, tercera: false };
      if (stage === 'tercera') return { ...prev, primera: false, segunda: false, tercera: true };
      if (stage === 'reset') return { ...prev, primera: true, segunda: true, tercera: true };
    });
    window.simplemaps_countrymap.zoom_level !== 'out' && window.simplemaps_countrymap.load();
  };

  useEffect(() => {
    const getStates = async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_STATES_API}/states`);
      const { data: sedesResults } = await axios.get(`${process.env.REACT_APP_STATES_API}/states/sedes`);
      setStatesData(data);
      setSedes(sedesResults);
    };
    getStates();
  }, []);

  useEffect(() => {
    const getStateData = () => {
      const state = statesData.find(state => state.code === currentState);
      const infoCats = [
        // 'Armonización Legislativa',
        // 'Modificación de Estatutos',
        'Oficinas de CFCRL',
        'Centros de conciliación locales',
        'Tribunales laborales federales',
        'Tribunales laborales locales',
        // 'Subsidios'
      ];
      const stateInfo = infoCats.map(cat => ({ title: cat, ...state }));
      setCurrentStateData(stateInfo);
    };
    statesData.length && currentState && getStateData();
  }, [currentState, statesData]);

  return (
    <div className='map-container container-fluid pt-5 pb-5'>
      <div className={`row mb-3 banner text-center ${currentState ? 'vanish' : ''}`}>
        <h2 className='title front'>MAPA DE AVANCES</h2>
        <h2 className='subtitle front'>por Entidad Federativa</h2>
      </div>
      <div className='row justify-content-center'>
        <div className={popup && windowWidth < 768 ? 'col-md-6 front' : 'col-md-6'}>
          {statesData.length ? (
            <Map
              statesData={statesData}
              sedes={sedes}
              setCurrentState={setCurrentState}
              filter={filter}
              setPopup={setPopup}
            />
          ) : (
            <Loading />
          )}
        </div>
        <div className={popup && windowWidth < 768 ? 'col-md-6' : 'col-md-6 front'}>
          {currentState && currentStateData.length ? (
            <>
              <InfoCarrousel data={currentStateData} />
              <div className='row align-items-center justify-content-center mt-3'>
                <div className='col-4 text-center'>
                  <button
                    className='btn regresar mx-auto'
                    id='reset'
                    onClick={filterStages}
                    style={{ fontWeight: '500' }}
                  >
                    REGRESAR
                  </button>
                </div>
              </div>
            </>
          ) : (
            <InfoBasic filterStages={filterStages} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MapDashboard;
