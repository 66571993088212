import { memo, useRef, useEffect } from 'react';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import ArmonizacionLegislativa from './ArmonizacionLegislativa';
import ModificacionEstatutos from './ModificacionEstatutos';
import OficinasCFCRL from './OficinasCFRL';
import CentrosConciliacionLocales from './CentrosConciliacionLocales';
import TribuanalesFederales from './TribunalesFederales';
import TribuanalesLocales from './TribunalesLocales';
import Subsidios from './Subsidios';

const InfoCarrousel = ({ data }) => {
  const ref = useRef();
  return (
    <div className='row carousel-container'>
      <ResponsiveContainer
        carouselRef={ref}
        render={(width, carouselRef) => {
          const slideW = width <= 720 ? 300 : 500;
          return (
            <StackedCarousel
              autoRotate={true}
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={slideW}
              carouselWidth={width}
              data={data}
              height={600}
              maxVisibleSlide={5}
              customScales={[1, 0.85, 0.7, 0.55]}
              transitionTime={450}
              useGrabCursor
            />
          );
        }}
      />
      <div className='carousel-control-prev' onClick={() => ref.current?.goBack()}>
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
      </div>
      <div className='carousel-control-next' onClick={() => ref.current?.goNext()}>
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
      </div>
    </div>
  );
};

const Card = memo(({ data, dataIndex, isCenterSlide, slideIndex, swipeTo }) => {
  const formatNumbers = (dataObject) => {

    Object.keys(dataObject).forEach(function(key, index) {
      if(typeof dataObject[key] === 'number' && dataObject[key] > 999) {
        dataObject[key] = dataObject[key].toLocaleString('es-MX');
      } else if(typeof dataObject[key] === 'object' && Array.isArray(dataObject[key])) {
        dataObject[key] = dataObject[key].map((tempObject) => {
          if(typeof tempObject === 'object' && Object.prototype.toString.call(tempObject) === '[object Object]') {
            Object.keys(tempObject).forEach(function(k) {
              if(typeof tempObject[k] === 'number' && tempObject[k] > 999) {
                tempObject[k] = tempObject[k].toLocaleString('es-MX');
              }
            });
          }
          return tempObject;
        });
      } else if(typeof dataObject[key] === 'object' && Object.prototype.toString.call(dataObject[key]) === '[object Object]') {
        Object.keys(dataObject[key]).forEach(function(k) {
          if(typeof dataObject[key][k] === 'number' && dataObject[key][k] > 999) {
            dataObject[key][k] = dataObject[key][k].toLocaleString('es-MX');
          }
        });
      }
     });

    return dataObject;
  };
  
  const cardData = formatNumbers(data[dataIndex]);

  useEffect(() => {
    isCenterSlide && swipeTo(-dataIndex);
    // eslint-disable-next-line
  }, [data, dataIndex, swipeTo]);
  
  
  
  const renderCardContent = () => {
    //eslint-disable-next-line
    switch (cardData.title) {
      case 'Armonización Legislativa':
        return <ArmonizacionLegislativa cardData={cardData} />;
      case 'Modificación de Estatutos':
        return <ModificacionEstatutos cardData={cardData} />;
      case 'Oficinas de CFCRL':
        return <OficinasCFCRL cardData={cardData} />;
      case 'Centros de conciliación locales':
        return <CentrosConciliacionLocales cardData={cardData} />;
      case 'Tribunales laborales federales':
        return <TribuanalesFederales cardData={cardData} />;
      case 'Tribunales laborales locales':
        return <TribuanalesLocales cardData={cardData} />;
      // case 'Subsidios':
      //   return <Subsidios cardData={cardData} />;
    }
  };

  return (
    <div className='row'>
      <div className='col'>
        <div
          className='card user-select-none'
          style={{ height: '600px', overflowY: 'auto' }}
          onClick={() => !isCenterSlide && swipeTo(slideIndex)}
        >
          <div className='card-body'>
            <div className='card-header'>
              <h2 className='text-center' style={{ fontWeight: '900' }}>
                {cardData.name.toUpperCase()}
              </h2>
            </div>
            <h5 className='card-title text-center mb-3 title-card' style={{ fontWeight: '700' }}>
              {cardData.title.toUpperCase()}
            </h5>
            {renderCardContent()}
          </div>
        </div>
      </div>
    </div>
  );
});

export default InfoCarrousel;
